import "./src/styles/global.css"
import React from "react";
import Modal from "react-modal";
import { BrowserRouter } from 'react-router-dom';

export const wrapPageElement = ({ element }) => {
  return <BrowserRouter>{element}</BrowserRouter>;
};

export const onInitialClientRender = () => {
  Modal.setAppElement('#___gatsby');
};